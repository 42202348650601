<template>
  <div class="footer" id="footer">
    <span>copyright ©️ 品课教育产品部出品</span>
  </div>
</template>
<style lang="scss" scoped>
// @media (max-height: 700px) {
//   #footer{
//     height: 3vw;
//     line-height: 3vw;
//   }
// }
.footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  height: 3vw;
  font-size: 1vw;
  color: #000000;
  // line-height:60px;
  line-height: 3vw;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    cursor: pointer;
  }
}
</style>