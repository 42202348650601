<template>
  <div class="personalCenter animate__animated animate__fadeIn">
    <Header :header_data="[{title:'个人中心'}]" />
    <div class="main">
      <div class="tabs">
        <el-tabs tab-position="left" v-model="tabs" @tab-click="tabClick" :stretch="false" style="height: 200px;">
          <!-- <el-tab-pane name="test">
            <template #label class="tablabel">
              <i>
                <img src="../../../assets/student/bluecs.png" v-if="tabs == 'test'" alt="">
                <img src="../../../assets/student/ccccs.png" v-else alt="">
              </i>
              <span>我的测试</span>
            </template>
          </el-tab-pane> -->
          <el-tab-pane label="我的金币" name="coins">
            <template #label >
              <div class="tablabel">
                <img src="../../../assets/student/bluejb.png" v-if="tabs == 'coins'" alt="">
                <img src="../../../assets/student/cccjb.png" v-else alt="">
                <span>我的金币</span>
              </div>
            </template>
          </el-tab-pane>
<!--          <el-tab-pane label="金币排行榜"  style="display:none;" name="ranking">-->
<!--            <template #label>-->
<!--              <div class="tablabel">-->
<!--                <img src="../../../assets/student/blueph.png" v-if="tabs == 'ranking'" alt="">-->
<!--                <img src="../../../assets/student/cccph.png" v-else alt="">-->
<!--                <span>金币排行榜</span>-->
<!--              </div>-->
<!--            </template>-->
<!--          </el-tab-pane>-->
          <el-tab-pane label="我的订单" name="order">
            <template #label >
              <div class="tablabel">
                <svg-icon icon="icon12" v-if="tabs == 'order'" class="svg"/>
                <svg-icon icon="icon11" v-else class="svg"/>
                <span>我的订单</span>
              </div>
            </template>
          </el-tab-pane>
        </el-tabs>
      </div>
      <router-view></router-view>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../components/zjfheader.vue";
import Footer from "../components/footer.vue";
export default {
  components:{
    Header,
    Footer
  },
  data(){
    return{
      tabs:'1',
      training:null, //select选择器
      timer: [], // 日期选择器
      btnState:1, // 1是发起兑换，2是查看明细
      dom:null,
    }
  },
  mounted(){
    this.tabs = this.$route.name
    // this.getData()
  },
  methods:{
    // 瀑布流获取数据
    getData(){
      // 获取需要绑定的table
      let dom = document.querySelector('.table')
      let _this=this
      dom.addEventListener('scroll', function(e) {
        // dom高度减去滚动条滚动距离 // 计算滚动条距离table底部的高度
        let distance = dom.scrollHeight - dom.scrollTop - dom.clientHeight
        // 滚动条到底，请求接口
        if(distance <= 1){
          // 请求完所有数据，提示
          setTimeout(() => {
            _this.$message.warning('没有更多！')
          }, 1000);
        }
      })
    },
    // tab切换
    tabClick(){
      if(this.tabs == 'coins'){
        this.$router.push({path:'coins'})
      } else if(this.tabs == 'test'){
        this.$router.push({path:'test'})
      } else if(this.tabs == 'ranking'){
        this.$router.push({path:'ranking'})
      } else if (this.tabs == 'order') {
        this.$router.push({path: 'order'})
      }
    },
    // 查看按钮/发起兑换
    state(){
      if(this.btnState == 1){
        this.btnState = 2
      } else {
        this.btnState = 1
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.personalCenter{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  .main{
    position: absolute;
    top: 90px;
    left: 0;
    bottom: 50px;
    right: 0;
    box-sizing: border-box;
    padding: 2% 0;
    .tabs{
      height: 100%;
      width: 10.5vw;
      border-radius: 10px;
      box-shadow: 0 0 8px 4px rgba(89,144,255,.2);
      float: left;
      box-sizing: border-box;
      padding-top: 2%;
    }
  }
}
.tablabel{
  display: none;
  position: relative;
  display: block;
  width:fit-content;
  width:-webkit-fit-content;
  width:-moz-fit-content;
  height: 100%;
  text-align: right;
  //width: 100%;
  img{
    display: inline-block;
    width: 1.1vw;
    height: 1.1vw;
    vertical-align: middle;
    margin-right: .2vw;
  }
  .svg{
    display: inline-block;
    vertical-align: middle;
    margin-right: .2vw;
    padding-top: 5px;
  }
  span{
    vertical-align: middle;
  }
}
</style>
<style lang="scss">
.personalCenter{
  .el-table{
    overflow: auto;
  }
  .el-tabs__header.is-left {
    width: 10.5vw;
    .el-tabs__active-bar{
      display: none;
    }
    #tab-first {
      font-size: 20px;
      padding: 0;
      // text-align: center;
    }
    .is-active {
      background-color: #d8effb;
    }
    .el-tabs__header.is-left .el-tabs__nav > div {
      width: 3px !important;
    }
  }
  .el-tabs__nav{
    padding-top: 10px;
  }
  .el-tabs__item{
    color: #999;
    height: 3vw;
    line-height: 3vw;
    font-size: 1.1vw;
  }
  .el-tabs__item.is-active {
    color: #409EFF !important;
    border-right: 5px solid #409EFF;
  }
  .el-tabs__item:hover {
    color: #409EFF !important;
  }
  .el-tabs__header.is-left .is-active{
    background: #FFFFFF !important;
    box-shadow: 0px 0px 10px 0px rgba(89, 144, 255, 0.3) !important;
  }
  .el-input__inner{
    height: 2vw;
    background: #F1F8FF;
  }
  .el-input__icon {
    line-height: inherit;
    width: 2vw;
  }
  .el-input__suffix-inner {
      display: block;
      height: 100%;
      // padding-top: .3vw;
  }
  .el-range-input{
    background: #F1F8FF;
  }
  .el-date-editor .el-range__icon{
    display: none;
  }
}
</style>